/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-02 19:08:35
 * @Module: 购买套餐
 */
 <template>
  <div class="packages">
    <ul class="packages-list">
      <li class="packages-list-item" :class="{'active':item.packageId==packageId}" @click="packageId=item.packageId" v-for="item in list" :key="item.packageId">
        <p class="packages-list-item-t">{{item.coins}}币</p>
        <p class="packages-list-item-c">
          <span style="font-size:18px;">￥</span>
          <span>{{item.price}}</span>
        </p>
        <p class="packages-list-item-b">￥{{item.coins}}</p>
      </li>
    </ul>
    <div class="button">
      <el-button type="primary" :disabled="!packageId" @click="postRecharge">确定购买</el-button>
    </div>
    <div v-html="h" />
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      packageId: 0,
      list: [],
      h: ''
    };
  },
  mounted () {
    this.postRechargePackage()
  },
  methods: {
    async postRechargePackage () {
      const { data, info, status } = await this.$api.postRechargePackage()
      if (status === 200) {
        this.list = data
      }
    },
    async postRecharge () {
      const { info, status } = await this.$api.postRecharge({
        packageId: this.packageId
      })
      if (status === 200) {
        this.h = info
        this.$nextTick(() => {
          document.forms[0].submit()
        })
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.packages {
  &-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .active {
      border: 1px solid $--color-primary;
      background: rgba($color: $--color-primary, $alpha: 0.05);
    }
    &-item {
      width: 200px;
      height: 120px;
      border: 1px solid #044a8f;
      margin-right: 29px;
      // margin-right: 3.15%;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      &-t {
        font-size: 18px;
        color: #044a8f;
      }
      &-c {
        font-size: 28px;
        margin-top: 10px;
        color: #044a8f;
      }
      &-b {
        font-size: 12px;
        margin-top: 6px;
        text-decoration: line-through;
      }
    }
  }
  .button {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}
</style>